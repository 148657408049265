



















































































import { signOut } from '@/utils/auth';
import { getDateDDMMYYYY } from '@/utils/functions';
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class Profile extends Vue {
  get date(): string {
    return getDateDDMMYYYY(this.$store.getters.user.date);
  }

  public signOut(): void {
    signOut();
  }
}
